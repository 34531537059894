import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import identityActions from '../../redux/actions/identity.actions';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LoginOrRegisterButtons from '../../components/LoginOrRegisterButtons/LoginOrRegisterButtons';
import redirectHelper from '../../helpers/redirectHelper';
import authActions from '../../redux/actions/auth.actions';
import cookieHelper from '../../helpers/cookieHelper';
import alertsActions from '../../redux/actions/alerts.actions';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { REQUEST_STATUS } from '../../models/common.model';
import Loader from '../../components/Loader/Loader';
import DifferentMailInfo from './DifferentMailInfo';

const JoinWorkspacePage = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const prevInvitationDetailStatus = useRef(null);
  const prevAcceptInvitationStatus = useRef(null);
  const prevRegisterIntoWorkspaceStatus = useRef(null);
  const {
    invitationDetailStatus,
    invitationDetails,
    acceptInvitationStatus,
    joinedWorkspaceIdentityId,
    registerIntoWorkspaceStatus,
    registerConfirmationToken,
    registerIntoWorkspaceErrors,
  } = useSelector((state) => state.identity);
  const { tokenStatus, userProfile } = useSelector((state) => state.auth);
  const isLoggedIn = tokenStatus === REQUEST_STATUS.SUCCESS;
  const hasDiffMails = userProfile?.email && userProfile?.email !== invitationDetails?.invitedEmail;
  const hasNoAccount = !invitationDetails?.userId && invitationDetails?.invitedEmail && !isLoggedIn;
  const onLogin = () => {
    const suffixUrl = invitationDetails?.invitedEmail
      ? `?email=${invitationDetails?.invitedEmail}&joinWorkspace=true`
      : '';
    redirectHelper.setRedirect(window.location.pathname);
    history.push(`/${i18n.language}${suffixUrl}`);
  };

  const onJoin = () => {
    const { inviteId } = match.params;
    dispatch(identityActions.joinWorkspace(inviteId));
  };

  const onRegister = () => {
    const { inviteId } = match.params;
    const { language } = i18n;

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA, { action: 'submit' })
        .then((token) => {
          dispatch(identityActions.registerIntoWorkspace(inviteId, language, token));
        });
    });
  };

  useEffect(() => {
    const { inviteId } = match.params;
    if (!inviteId) {
      dispatch(alertsActions.error(t('inviteWorkspacePage.inviteNotFound')));
      history.push(`/${i18n.language}`);
    } else {
      dispatch(identityActions.getInvitationDetails(inviteId));
      dispatch(authActions.checkTokenStatus(['AUTH_USER_GET_PROFILE']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  useEffect(() => {
    if (
      prevInvitationDetailStatus.current === REQUEST_STATUS.PENDING &&
      invitationDetailStatus === REQUEST_STATUS.ERROR
    ) {
      dispatch(alertsActions.error(t('inviteWorkspacePage.inviteNotFound')));
      history.push(`/${i18n.language}`);
    }
    // if (invitationDetails?.userId && !isLoggedIn) {
    //   redirectHelper.setRedirect(history.location.pathname);
    //   history.push(`/${i18n.language}?email=${invitationDetails?.invitedEmail}&joinWorkspace=true`);
    // }

    prevInvitationDetailStatus.current = invitationDetailStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationDetailStatus]);

  useEffect(() => {
    if (
      prevAcceptInvitationStatus.current === REQUEST_STATUS.PENDING &&
      acceptInvitationStatus === REQUEST_STATUS.SUCCESS
    ) {
      cookieHelper.setCookie('activeIdentity', joinedWorkspaceIdentityId.identityId);
      redirectHelper.setRedirect(`${process.env.REACT_APP_APP_URL}/drive?s=joinWorkspace`);
      redirectHelper.redirect();
    }
    prevAcceptInvitationStatus.current = acceptInvitationStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptInvitationStatus]);

  useEffect(() => {
    if (prevRegisterIntoWorkspaceStatus.current === REQUEST_STATUS.PENDING) {
      if (registerIntoWorkspaceStatus === REQUEST_STATUS.ERROR) {
        const firstCode = registerIntoWorkspaceErrors[0]?.code || null;
        switch (firstCode) {
          case 'NOT_FOUND':
            dispatch(alertsActions.error(t('inviteWorkspacePage.inviteNotFound')));
            history.push(`/${i18n.language}`);
            break;
          case 'INTERNAL_ERROR':
          default:
            dispatch(alertsActions.error(t('inviteWorkspacePage.internalError')));
            break;
        }
      } else if (registerIntoWorkspaceStatus === REQUEST_STATUS.SUCCESS) {
        history.push(
          `/${i18n.language}/${t('urls.activate')}/${registerConfirmationToken}&joinWorkspace=true`
        );
      }
    }
    prevRegisterIntoWorkspaceStatus.current = registerIntoWorkspaceStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerIntoWorkspaceStatus]);

  useEffect(() => {
    if (tokenStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(authActions.getUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenStatus]);

  return (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="pl-3 pr-3">
          <Col>
            <Header redirectType="register" noRedirect />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center join-workspace-page">
          {hasDiffMails ? (
            <DifferentMailInfo />
          ) : (
            <>
              {invitationDetailStatus === REQUEST_STATUS.SUCCESS && (
                <Col>
                  <div className="d-flex justify-content-center mb-4">
                    <UserAvatar size="extraLarge">{invitationDetails.name}</UserAvatar>
                  </div>
                  <h2 className="text-center text-color-primary mb-2 mb-sm-6">
                    {invitationDetails.name}
                  </h2>
                  <h3 className="text-center mb-2 mb-sm-6">{t('inviteWorkspacePage.invitedTo')}</h3>
                  <div className="d-flex justify-content-center">
                    {invitationDetailStatus === REQUEST_STATUS.IDLE ||
                    invitationDetailStatus === REQUEST_STATUS.PENDING ? (
                      <Loader />
                    ) : (
                      <>
                        {hasNoAccount && (
                          <div className="login-register-buttons-wrapper text-center w-100">
                            <h6 className="m-0 font-weight-semi-bold">
                              {t('inviteWorkspacePage.registerToJoinWorkspace')}
                            </h6>
                            <Button
                              className="w-100 text-color-primary mt-4 mt-sm-5 mb-2 mb-sm-5"
                              variant="light"
                              onClick={onRegister}
                            >
                              {t('loginRegisterButtons.newAccount')}
                            </Button>
                          </div>
                        )}
                        {!hasNoAccount && !invitationDetails?.userId && (
                          <div className="login-register-buttons-wrapper text-center w-100">
                            <h6 className="m-0 font-weight-semi-bold">
                              {t('loginRegisterButtons.heading')}
                            </h6>
                            <Button className="w-100 mt-4 mt-sm-5 mb-2 mb-sm-5" onClick={onLogin}>
                              {t('loginRegisterButtons.login')}
                            </Button>
                          </div>
                        )}
                        {!hasNoAccount && invitationDetails?.userId && (
                          <LoginOrRegisterButtons
                            onLogin={onLogin}
                            onRegister={onRegister}
                            isLoggedIn={tokenStatus === REQUEST_STATUS.SUCCESS}
                            loggedLabel={t('inviteWorkspacePage.joinWorkspace')}
                            isDisabled={
                              acceptInvitationStatus === REQUEST_STATUS.PENDING ||
                              acceptInvitationStatus === REQUEST_STATUS.SUCCESS
                            }
                            onLoggedAction={onJoin}
                          />
                        )}
                      </>
                    )}
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
        <Row className="pl-3 pr-3">
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JoinWorkspacePage;
